import React, { ReactElement } from "react";
import Image from "next/image";
import styles from "./style.module.scss";
export default function PickRightService(): ReactElement {
    return (
        <section className="mt-10 lg:my-16">
            <div className="max-w-7xl mx-auto block px-4vw lg:px-4">
                <div className="flex flex-col lg:flex-row justify-between">
                    <div className={`${styles["brief"]} w-full lg:w-3/5`}>
                        <h2 className="text-xl capitalize lg:text-2xl mb-8 font-bold">
                            Credit Card Processor Quick Facts
                        </h2>
                        <div className="text-sm lg:text-lg mb-12 font-light">
                            <p className="mb-6 lg:mb-8">
                                <strong>
                                    When it comes to online shopping and travel
                                    purchases,
                                </strong>{" "}
                                credit cards are the preferred payment method
                                for many consumers. According to a survey, 48%
                                of respondents prefer using credit cards for
                                online shopping, while 47% prefer it for online
                                travel purchases, over options like debit cards
                                and PayPal.
                            </p>
                        </div>
                    </div>
                    <div className={styles["imgZone"]}>
                        <Image
                            src="/assets/images/pic.svg"
                            className={styles["backgroundImg"]}
                            alt="Credit Card Processor Quick Facts"
                            layout="responsive"
                            width={329}
                            height={253}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
